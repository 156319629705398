<template>
    <Toast :baseZIndex="9999" />
    <div class="p-grid">
        <div class="p-col-12">
            <div class="p-d-flex p-ai-center p-jc-between p-flex-column p-flex-lg-row p-mb-1">
                <h5>Lokneta App Banner Images</h5>
                <div>
                    <Button label="Dashboard" class="p-button-sm p-mr-2" @click="goToDashboard" icon="pi pi-th-large" />
                    <Button label="Previous" class="p-button-sm p-mr-1" @click="goToPrevious"
                        icon="pi pi-chevron-left" />
                    <!-- <Button label="Next" icon="pi pi-chevron-right" @click="goToNext" class="p-button-sm"
                        iconPos="right" /> -->
                </div>
            </div>
            <div class="card p-mt-4">
                <div>
                    <span class="subtext p-mt-2 p-text-capitalize">
                        <h6>Client Name : {{ this.localClientName ? this.localClientName : '-' }} </h6>
                    </span>
                </div>
                <div style="position: relative" v-if="!showLoader">
                    <div class="p-py-4 p-px-3">
                        <div class="p-fluid p-formgrid p-grid">
                            <DataTable :value="imageData" :lazy="true" :paginator="true" :rows="30"
                                :totalRecords="totalRecords" :loading="loading" @page="onPage($event)"
                                class="p-datatable-users" data-key="mll1" :rowHover="true"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
                                <Toolbar>
                                    <template v-slot:right>
                                        <Button label="Add New Banner Image" icon="pi pi-plus" @click="onAddImage" />
                                    </template>
                                </Toolbar>
                                <template #empty> No data found. </template>
                                <template #loading> Loading data. Please wait... </template>
                                <Column header="Sr." headerStyle="width: 4%">
                                    <template #body="{ index }">
                                        <div>{{ genrateSrNo(index) }}</div>
                                    </template>
                                </Column>
                                <Column header="Image Name" headerStyle="width: 40%">
                                    <template #body="{ data }">
                                        <div>{{ data.mll4 }}</div>
                                    </template>
                                </Column>
                                <Column header="Uploaded Images" headerStyle="width: 20%">
                                    <template #body="{ data }">
                                        <div>
                                            <span v-if="data.mll5 != null && data.mll5 != ''">
                                                <img :src="data.mll5" width="100" style="vertical-align: middle" />
                                            </span>
                                            <span v-if="data.mll5 == null || data.mll5 == ''">-</span>
                                        </div>
                                    </template>
                                </Column>
                                <Column header="Image Used For" headerStyle="width: 20%">
                                    <template #body="{ data }">
                                        <div class="p-text-capitalize">
                                            <span v-if="data.mll9 == 1"> Banner Image</span>
                                            <span v-if="data.mll9 == 2"> Login Mobile Number Screen Image</span>
                                            <span v-if="data.mll9 == 3"> Sign up Screen Image</span>
                                            <span v-if="data.mll9 == 4"> Splash Screen Image</span>
                                            <span v-if="data.mll9 == 5"> OTP Screen Image</span>

                                        </div>
                                    </template>
                                </Column>
                                <Column header="Status" headerStyle="width: 10%">
                                    <template #body="{ data }">
                                        <div class="p-text-capitalize">
                                            <span v-if="data.mll6 == 1"> Active</span>
                                            <span v-if="data.mll6 == 0"> Inactive</span>
                                        </div>
                                    </template>
                                </Column>
                                <Column header="Action" headerStyle="width: 6%">
                                    <template #body="{ data }">
                                        <Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2"
                                            @click="onEditImage(data)" />
                                    </template>
                                </Column>
                            </DataTable>
                        </div>
                    </div>
                </div>
                <div v-if="showLoader" style="height: 390px; position: relative">
                    <div class="custom-modal-spinner-loader">
                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Add/Edit Image Dialog starts here -->
    <Dialog v-model:visible="isDialogOpen" :style="{ width: '800px' }" :modal="true" class="p-fluid"
        :header="dialogHeader">
        <div style="position: relative">
            <div class="p-pt-2 p-px-3">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-6">
                        <label for="image">
                            Image
                            <span class="p-invalid p-error">*</span>
                        </label>
                        <FileUpload mode="basic" ref="imagefile" :maxFileSize="2000000" accept="image/*"
                            chooseLabel="Upload Image" auto @select="handleImageUpload" />
                        <small class="p-invalid p-error" v-if="imageerror">{{ imageerror
                            }}</small>
                        <small class="p-invalid p-error" v-if="v$.imagefile.$error && imageerror == ''">{{
                            v$.imagefile.$errors[0].$message
                        }}</small>
                        <div>
                            <img v-if="imageUrl && imageUrl.url" class="p-pt-2" role="presentation" :alt="imageUrl.name"
                                :src="imageUrl.url" width="200" height="150" />
                        </div>
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <label for="imageFor">
                            Image Used For
                            <span class="p-invalid p-error">*</span>
                        </label>
                        <Dropdown id="imageFor" v-model="imageFor" :options="imageForList" optionLabel="label"
                            placeholder="Select ..." appendTo="body"></Dropdown>
                        <small class="p-invalid p-error" v-if="v$.imageFor.$error">{{
                            v$.imageFor.$errors[0].$message
                            }}</small>
                    </div>
                </div>
                <div class="p-fluid p-formgrid p-grid">

                    <div class="p-field p-col-12 p-md-6">
                        <label for="img_discription">
                            Image Description 1
                            <!-- <span class="p-invalid p-error">*</span> -->
                        </label>
                        <InputText id="img_discription" class="p-text-capitalize" v-model.trim="imgDiscriptionfirst"
                            required="true" maxlength="21" autofocus />
                        <!-- <small class="p-invalid p-error" v-if="v$.imgDiscriptionfirst.$error">{{
                                v$.imgDiscriptionfirst.$errors[0].$message
                            }}</small> -->
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <label>Font Color for Image Description 1</label>
                        <div class="p-field-radiobutton" style="margin-top: 2%;">
                            <RadioButton id="fontColorWhite1" name="option" v-bind:value="1" v-model="fontColor1"
                                :checked="fontColor1 == 1" />
                            <label for="fontColorWhite1" class="p-mr-2">White</label>
                            <RadioButton id="fontcolorBlue1" name="option" v-bind:value="0" v-model="fontColor1"
                                :checked="fontColor1 == 0" />
                            <label for="fontcolorBlue1" class="p-mr-2">Blue</label>
                        </div>
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <label for="img_discription2">
                            Image Description 2
                            <!-- <span class="p-invalid p-error">*</span> -->
                        </label>
                        <InputText id="img_discription2" class="p-text-capitalize" v-model.trim="imgDiscriptionsecond"
                            required="true" maxlength="70" autofocus />
                        <!-- <small class="p-invalid p-error" v-if="v$.imgDiscriptionsecond.$error">{{
                                v$.imgDiscriptionsecond.$errors[0].$message
                            }}</small> -->
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <label>Font Color for Image Description 2</label>
                        <div class="p-field-radiobutton" style="margin-top: 2%;">
                            <RadioButton id="fontColorWhite2" name="option" v-bind:value="1" v-model="fontColor2"
                                :checked="fontColor2 == 1" />
                            <label for="fontColorWhite2" class="p-mr-2">White</label>
                            <RadioButton id="fontcolorBlue2" name="option" v-bind:value="0" v-model="fontColor2"
                                :checked="fontColor2 == 0" />
                            <label for="fontcolorBlue2" class="p-mr-2">Blue</label>
                        </div>
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <label for="img_discription2">
                            Image Description 3
                            <!-- <span class="p-invalid p-error">*</span> -->
                        </label>
                        <InputText id="img_discription3" class="p-text-capitalize" v-model.trim="imgDiscriptionthird"
                            required="true" maxlength="70" autofocus />
                        <!-- <small class="p-invalid p-error" v-if="v$.imgDiscriptionsecond.$error">{{
                                v$.imgDiscriptionsecond.$errors[0].$message
                            }}</small> -->
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <label>Font Color for Image Description 3</label>
                        <div class="p-field-radiobutton" style="margin-top: 2%;">
                            <RadioButton id="fontColorWhite3" name="option" v-bind:value="1" v-model="fontColor3"
                                :checked="fontColor3 == 1" />
                            <label for="fontColorWhite3" class="p-mr-2">White</label>
                            <RadioButton id="fontcolorBlue3" name="option" v-bind:value="0" v-model="fontColor3"
                                :checked="fontColor3 == 0" />
                            <label for="fontcolorBlue3" class="p-mr-2">Blue</label>
                        </div>
                    </div>

                    <div class="p-field p-col-12 p-md-6">
                        <label>status <span class="p-invalid p-error"> *</span></label>
                        <div class="p-field-radiobutton">
                            <RadioButton id="status_active" name="option" v-bind:value="1" v-model="statustype"
                                :checked="statustype == 1" />
                            <label for="status_active" class="p-mr-2">Active</label>
                            <RadioButton id="status_Inactive" name="option" v-bind:value="0" v-model="statustype"
                                :checked="statustype == 0" />
                            <label for="status_Inactive" class="p-mr-2">Inactive</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <template #footer>
            <Button label="Submit" :icon="!showLoader ? 'pi pi-check' : 'pi pi-spin pi-spinner indigo-color'"
                class="p-button-text p-ml-auto" @click="addImage" style="width: 90px;" :disabled="showLoader"></Button>
        </template>
    </Dialog>
    <!-- Add/Edit Image Dialog ends here -->
</template>

<script>
import ApiService from '../../service/ApiService';
import useValidate from '@vuelidate/core';
import { useRoute } from 'vue-router';
import router from '@/router';
import { required, helpers } from '@vuelidate/validators';

export default {
    data() {
        return {
            imageData: [],
            v$: useValidate(),
            isDialogOpen: false,
            isSubmitted: false,
            showLoader: false,
            loading: false,
            page_no: 0,
            totalRecords: 0,
            imagefile: null,
            imageUrl: null,
            dialogHeader: "",
            imageerror: '',
            mll1: 0,
            statustype: 1,
            imgDiscriptionfirst: '',
            imgDiscriptionsecond: '',
            imgDiscriptionthird: '',
            fontColor1: 1,
            fontColor2: 1,
            fontColor3: 1,
            routeParam: 0,
            localClientName: '',
            imageForList: [
                { label: 'Banner Image', value: 1 },
                { label: 'Login Mobile Number Screen Image', value: 2 },
                { label: 'Sign up Screen Image', value: 3 },
                { label: 'Splash Screen Image', value: 4 },
                { label: 'OTP Screen Image', value: 5 }
            ],
            imageFor: '',
        };
    },

    validations() {
        return {
            imagefile: { required: helpers.withMessage('Please select image', required) },
            imageFor: { required: helpers.withMessage('Please select image used for type', required) }
        };
    },

    ApiService: null,
    interval: null,

    created() {
        this.ApiService = new ApiService();
    },

    mounted() {

        const route = useRoute();
        this.routeParam = route.params.clientSubId;
        this.localClientName = localStorage.localClientName;
        this.getBannerImageList({ page_no: this.page_no, clientId: this.routeParam });
    },

    methods: {
        onAddImage() {
            this.v$.$reset();
            this.xyz = "";
            this.dialogHeader = 'Add Banner Image';
            this.isDialogOpen = true;
            this.imagefile = '';
            this.mll1 = 0;
            this.imageUrl = null;
            this.statustype = 1;
            this.imgDiscriptionfirst = '';
            this.imgDiscriptionsecond = '';
            this.imgDiscriptionthird = '';
            this.fontColor1 = 1;
            this.fontColor2 = 1;
            this.fontColor3 = 1;
            this.imageFor = '';
        },

        onEditImage(data) {
            this.dialogHeader = "Edit Banner Image";
            this.isDialogOpen = true;
            this.imagefile = data.mll4;
            this.imgDiscriptionfirst = data.mll7;
            this.imgDiscriptionsecond = data.mll8;
            this.imgDiscriptionthird = data.mll10;
            this.statustype = data.mll6;

            if (data.mll11 == '#000000')
                this.fontColor1 = 1;
            else if (data.mll11 == '#062DA1')
                this.fontColor1 = 0;

                if (data.mll12 == '#000000')
                this.fontColor2 = 1;
            else if (data.mll12 == '#062DA1')
                this.fontColor2 = 0;

                if (data.mll13 == '#000000')
                this.fontColor3 = 1;
            else if (data.mll13 == '#062DA1')
                this.fontColor3 = 0;

            this.mll1 = data.mll1;
            this.imageUrl = { name: data.mll4, url: data.mll5 };
            this.imageFor = '';
            this.imageFor = this.imageForList.find(image => image.value === data.mll9)
        },

        async getBannerImageList(params) {
            try {
                this.loading = true;
                const result = await this.ApiService.getBannerImageList({ ...params })
                this.loading = false;
                if (!result.success) {
                    return this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong.', life: 3000 });
                }
                this.imageData = result.result;
                this.totalRecords = result.count;
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong.', life: 3000 });
            }
        },

        async addImage() {
            this.isSubmitted = true;
            this.v$.$validate();
            if (this.v$.error) return;
            let formData = new FormData();
            formData.append("mll1", this.mll1);
            formData.append("mll2", this.routeParam);
            formData.append("mll3", this.localClientName);
            formData.append("mll4", this.imagefile);
            formData.append("mll6", this.statustype);
            formData.append("mll7", this.imgDiscriptionfirst);
            formData.append("mll8", this.imgDiscriptionsecond);
            formData.append("mll9", this.imageFor.value);
            formData.append("mll10", this.imgDiscriptionthird);

            if (this.fontColor1 == 1)
                formData.append("mll11", '#000000');
            else if (this.fontColor1 == 0)
                formData.append("mll11", '#062DA1');

            if (this.fontColor2 == 1)
                formData.append("mll12", '#000000');
            else if (this.fontColor2 == 0)
                formData.append("mll12", '#062DA1');

            if (this.fontColor3 == 1)
                formData.append("mll13", '#000000');
            else if (this.fontColor3 == 0)
                formData.append("mll13", '#062DA1');


            if (!this.v$.$error) {
                this.loading = this.showLoader = true;
                let result = await this.ApiService.createUpdateBannerImages(formData)
                this.loading = this.showLoader = this.isDialogOpen = false;
                if (result.success) {
                    this.getBannerImageList({ page_no: this.page_no, clientId: this.routeParam });
                    this.imagefile = null;
                    this.$toast.add({ severity: 'success', summary: 'Success', detail: result.message, life: 3000 });
                } else {
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: result.message, life: 3000 });
                }
            }
        },

        handleImageUpload() {
            var imagefile = this.$refs.imagefile.files[0];
            let allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
            if (!imagefile) {
                this.imageerror = 'Please select image';
            } else if (!imagefile.size > 2048) {
                this.imageerror = 'File size cannot exceed more than 2MB';
                this.imageUrl = null;
            } else if (!imagefile.name.match(allowedExtensions)) {
                this.imageerror = 'Invalid file type';
                this.imageUrl = null;
            } else {
                this.imagefile = this.$refs.imagefile.files[0];
                this.imageUrl = { name: this.imagefile.name, url: this.imagefile.objectURL };
                this.imageerror = '';
            }
        },

        onPage(event) {
            this.loading = true;
            this.page_no = event.page;
            this.getBannerImageList({ page_no: event.page, clientId: this.routeParam });
        },
        goToDashboard() {
            router.push({
                name: "clientdashboard",
                params: { clientId: this.routeParam },

            });
        },
        goToPrevious() {
            router.push({
                name: "whatsapp-numbers",
                params: { clientSubId: this.routeParam, clientnamevalue: this.localClientName },

            });
        },
        // goToNext() {
        //     router.push({
        //         name: "client-document",
        //         params: { clientSubId: this.routeParam },

        //     });
        // }
    }
};
</script>

<style scoped></style>